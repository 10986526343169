var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "fill-height": ""
    }
  }, [_c('div', {
    class: `conBox ${_vm.isAdmin ? `admin` : `popupList`}`
  }, [_vm.isAdmin ? _c('div', {
    staticClass: "srhTop"
  }, [_c('div', {
    class: 'srhBox ' + _vm.srhBoxFocus
  }, [_c('label', {
    staticClass: "sel"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.APIList.APIReq.searchType,
      expression: "APIList.APIReq.searchType"
    }],
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.APIList.APIReq, "searchType", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        _vm.APIList.APIReq.searchQuery = '';
      }]
    }
  }, _vm._l(_vm.searchItems, function (item, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": item.value
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 0)]), _c('label', {
    staticClass: "tex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.APIList.APIReq.searchQuery,
      expression: "APIList.APIReq.searchQuery"
    }],
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('common.msg.search.query')
    },
    domProps: {
      "value": _vm.APIList.APIReq.searchQuery
    },
    on: {
      "focus": function ($event) {
        _vm.srhBoxFocus = 'focusOn';
      },
      "blur": function ($event) {
        _vm.srhBoxFocus = '';
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.APIList.APIReq.pageNo = 1;
        _vm.getList();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.APIList.APIReq, "searchQuery", $event.target.value);
      }
    }
  })]), _c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "srhBtn btn solid",
    on: {
      "click": _vm.search
    }
  }, [_c('i', {
    staticClass: "micon"
  }, [_vm._v("search")])])]), _vm.isAdmin ? _c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn solid",
    attrs: {
      "id": "gd_regBtn"
    },
    on: {
      "click": function ($event) {
        return _vm.checkActivatedUserCnt();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('page.user.list.button')))]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "divBox"
  }, [_vm.isAdmin ? _c('div', {
    staticClass: "checkboxArea"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t('page.user.list.text.accountNo')) + ": "), _c('span', [_vm._v(_vm._s(_vm.activeUserCnt))]), _vm._v(" / " + _vm._s(_vm.userMaxCnt))]), _c('div', [_c('span', {
    staticClass: "labelText"
  }, [_vm._v(_vm._s(_vm.$t('page.user.list.text.checkbox')))]), _c('v-checkbox', {
    attrs: {
      "color": "indigo",
      "label": "",
      "value": "true",
      "hide-details": ""
    },
    on: {
      "change": _vm.chgOnlyActive
    },
    model: {
      value: _vm.APIList.APIReq.onlyActive,
      callback: function ($$v) {
        _vm.$set(_vm.APIList.APIReq, "onlyActive", $$v);
      },
      expression: "APIList.APIReq.onlyActive"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "borderWrap"
  }, [_c('div', {
    ref: "scrollContainer",
    staticClass: "tblScroll tblFixed",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('table', {
    staticClass: "tbl"
  }, [_c('caption', [_vm._v("사용자 계정 목록")]), _c('colgroup', [!_vm.isAdmin ? _c('col', {
    staticStyle: {
      "width": "10%"
    }
  }) : _vm._e(), _c('col'), _c('col', {
    staticStyle: {
      "width": "22%"
    }
  }), _vm.isAdmin ? _c('col', {
    staticStyle: {
      "width": "18%"
    }
  }) : _vm._e(), _c('col', {
    staticStyle: {
      "width": "20%"
    }
  }), _vm.isAdmin ? _c('col', {
    staticStyle: {
      "width": "12%"
    }
  }) : _vm._e()]), _c('thead', [_c('tr', _vm._l(_vm.filteredUserHeader, function (item, i) {
    return _c('th', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple"
      }],
      key: i,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.setSortType(`${item.value}`, $event);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " "), item.value != '' && item.value != 'isActive' && item.value != 'role' ? _c('span', {
      staticClass: "icon"
    }) : _vm._e()]);
  }), 0)]), _vm.isGetingItems ? _c('tbody', _vm._l(_vm.APIList.preSize, function (n) {
    return _c('tr', {
      key: n
    }, _vm._l(_vm.isAdmin ? 5 : 4, function (na) {
      return _c('td', {
        key: na,
        staticStyle: {
          "padding": "0 1rem"
        }
      }, [_c('ContentLoader', {
        attrs: {
          "speed": 1,
          "width": "100",
          "height": 12
        }
      }, [_c('rect', {
        attrs: {
          "x": "15",
          "y": "3",
          "rx": "3",
          "ry": "3",
          "width": 70,
          "height": 7
        }
      })])], 1);
    }), 0);
  }), 0) : _vm._e(), !_vm.isGetingItems && _vm.filteredUserList == 0 ? _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": _vm.isAdmin ? `5` : `4`
    }
  }, [_vm._v(_vm._s(_vm.$t('common.msg.search.noResult')))])])]) : _c('tbody', _vm._l(_vm.filteredUserList, function (item, index) {
    return _c('tr', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.selectedUser(item.no);
        }
      }
    }, [!_vm.isAdmin ? _c('td', {
      staticClass: "text-center"
    }, [_c('label', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.transfereeNo,
        expression: "transfereeNo"
      }],
      staticClass: "selRadi",
      attrs: {
        "type": "radio",
        "name": "radi"
      },
      domProps: {
        "value": item.no,
        "checked": _vm._q(_vm.transfereeNo, item.no)
      },
      on: {
        "change": function ($event) {
          _vm.transfereeNo = item.no;
        }
      }
    })])]) : _vm._e(), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.loginId))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.userNm))]), _vm.isAdmin ? _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(item.role === "A" ? _vm.$t('page.myPage.userType.adm') : _vm.$t('page.myPage.userType.usr')))]) : _vm._e(), _c('td', [_vm._v(_vm._s(item.crtDtStr))]), _vm.isAdmin ? _c('td', [_c('v-chip', {
      class: item && _vm.getStateChipClass(item.isActive)
    }, [_vm._v(_vm._s(item.isActive ? _vm.$t('page.myPage.userState.enable') : _vm.$t('page.myPage.userState.disable')))])], 1) : _vm._e()]);
  }), 0)])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }