import APIReq from "../_APIReq";
import { API_URL, API_COMMON_ERROR_MSG } from "../../plugins/Const";

const api = {
    request: async(pageNo, pageSize, searchType, searchQuery, onlyActive, sortBy=[], isAdmin) => {
        let retData = {
            isSuccess: false,
            errorMsg: '',
            userList: [],
            total: 0,
            status: 200,
        };
        try {
            const res = await APIReq(`${API_URL}/users/list`, { 
                pageNo: pageNo,
                pageSize: pageSize,
                searchType: searchType,
                searchQuery: searchQuery,
                onlyActive: onlyActive,
                sortBy: sortBy,
                admin: isAdmin
            }, 'post');
            if (res && res.data && res.data.result) {
                retData.isSuccess = res.data.result;
                retData.userList = res.data.list;
                retData.total = res.data.total;
                retData.activeUserCnt = res.data.activeUserCnt;
            } else if (res && res.data && res.data.error) {
                retData.isSuccess = false;
                retData.errorMsg = res.data.error;
            } else {
                retData.isSuccess = false;
                retData.errorMsg = API_COMMON_ERROR_MSG;
            }
        } catch (e) {
            retData.isSuccess = false;
            retData.status = e.response.status;
            retData.errorMsg = e.response.data.message ?? API_COMMON_ERROR_MSG;
        }
        return retData;
    }
};
export default api